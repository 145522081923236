import React from 'react';
import Card from 'react-bootstrap/Card';
import './Skill.css';
import Carousel from 'react-bootstrap/Carousel';
import one from '../../assets/img/1.png';
import two from '../../assets/img/2.png';
import three from '../../assets/img/3.png';
import four from '../../assets/img/4.png';
import five from '../../assets/img/5.png';
import SkillLogo from './SkillLogo';
import user from '../../assets/img/skillls.png';

const Skill = () => {
  return (
    <>

    <div style={{backgroundColor: '#f8f9fa'}}> 
        <Card  style={{border: 'none', textAlign: 'center', backgroundColor: '#f8f9fa'}}>
        
    <Card.Body>
    <img src={user} alt="" style={{height: '80px', width: '80px'}} />
    <br />
      <Card.Text className='skill-text'>
      As a frontend development, I possess a range of skills <br /> that enable me to created visually appealing and <br /> responsive user interfaces for websites. Some of the <br /> projects I've mentioned in the section below this one.
      </Card.Text>

   
    </Card.Body>

  </Card>

  <SkillLogo />
  </div>
    </>
  )
}

export default Skill
import React from 'react';
import './ProjectHero.css';

const ProjectHero = () => {
  return (
    <>
        <div className='project_hero' style={{textAlign: 'center'}} id='project'>
            <section>
                     
                     <div className='projectheroheading'>
                      <h1 style={{color: 'whitesmoke' ,textShadow: '2px 2px black'}}>I love making cool shit.</h1>
                     </div>
            </section>
        </div>
    </>
  )
}

export default ProjectHero
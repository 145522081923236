import React from 'react';
import './ResumeHero.css';

const ResumeHero = () => {
  return (
   <>
    <div className='resume_hero' style={{textAlign: 'center'}} id='resume'>
            <section>
                     
                     <div className='resumeheroheading'>
                      <h1 style={{color: 'whitesmoke' ,textShadow: '2px 2px black'}}>And here's my resume</h1>
                     </div>
            </section>
        </div>
   </>
  )
}

export default ResumeHero
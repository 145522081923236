import React from 'react';
import './AboutMeHero.css';

const AboutMeHero = () => {
  return (
    <>
        <>
        <div className='about_hero' style={{textAlign: 'center'}} id='about'>
            <section>
                     
                     <div className='aboutheroheading'>
                      <h1 style={{color: 'whitesmoke' ,textShadow: '2px 2px black'}}>And a little bit about me..</h1>
                     </div>
            </section>
        </div>
    </>
    </>
  )
}

export default AboutMeHero
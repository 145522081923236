import React from 'react';
import './CompanyHero.css';

const CompanyHero = () => {
  return (
    <>
        <div className='work_hero' style={{textAlign: 'center'}} id='company'>
            <section>
                     
                     <div className='workheroheading'>
                      <h1 style={{color: 'whitesmoke' ,textShadow: '2px 2px black'}}>I've worked at a few places too.</h1>
                     </div>
            </section>
        </div>
    </>
  )
}

export default CompanyHero
import React from 'react';
import './SkillHero.css';


const SkillHero = () => {
  return (
    <>
        <div className='skill_hero' style={{textAlign: 'center'}} id='skills'>
            <section>
                     
                     <div className='skillheroheading'>
                      <h1 style={{color: 'whitesmoke' ,textShadow: '2px 2px black'}}>Skilling up is fun.</h1>
                     </div>
            </section>
        </div>
    </>
  )
}

export default SkillHero